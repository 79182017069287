/* eslint-disable global-require */
import React, { useState, useEffect, useContext } from 'react';
import { IntlProvider } from 'react-intl';

import enMessages from '../public/l10n/en.json';

export const LangContext = React.createContext<{
    language: string;
    setLanguage: (language: string) => void;
}>({
    language: 'en',
    setLanguage: () => {},
});

// polyfill for Intl.PluralRules (e.g IE11 & Safari 12-)
if (!Intl.PluralRules) {
    require('@formatjs/intl-pluralrules/polyfill');
    require('@formatjs/intl-pluralrules/dist/locale-data/zh');
    require('@formatjs/intl-pluralrules/dist/locale-data/uk');
    require('@formatjs/intl-pluralrules/dist/locale-data/ru');
    require('@formatjs/intl-pluralrules/dist/locale-data/pt');
    require('@formatjs/intl-pluralrules/dist/locale-data/nl');
    require('@formatjs/intl-pluralrules/dist/locale-data/ja');
    require('@formatjs/intl-pluralrules/dist/locale-data/it');
    require('@formatjs/intl-pluralrules/dist/locale-data/id');
    require('@formatjs/intl-pluralrules/dist/locale-data/fr');
    require('@formatjs/intl-pluralrules/dist/locale-data/es');
    require('@formatjs/intl-pluralrules/dist/locale-data/el');
    require('@formatjs/intl-pluralrules/dist/locale-data/de');
    require('@formatjs/intl-pluralrules/dist/locale-data/bn');
    require('@formatjs/intl-pluralrules/dist/locale-data/cs');
}

// polyfill for Intl.RelativeTimeFormat (e.g IE11, Edge, Safari 13-)
// TODO: TS types are missing Intl.RelativeTimeFormat
// if (!Intl.RelativeTimeFormat) {
require('@formatjs/intl-relativetimeformat/polyfill');
require('@formatjs/intl-relativetimeformat/dist/locale-data/zh');
require('@formatjs/intl-relativetimeformat/dist/locale-data/uk');
require('@formatjs/intl-relativetimeformat/dist/locale-data/ru');
require('@formatjs/intl-relativetimeformat/dist/locale-data/pt');
require('@formatjs/intl-relativetimeformat/dist/locale-data/nl');
require('@formatjs/intl-relativetimeformat/dist/locale-data/ja');
require('@formatjs/intl-relativetimeformat/dist/locale-data/it');
require('@formatjs/intl-relativetimeformat/dist/locale-data/id');
require('@formatjs/intl-relativetimeformat/dist/locale-data/fr');
require('@formatjs/intl-relativetimeformat/dist/locale-data/es');
require('@formatjs/intl-relativetimeformat/dist/locale-data/el');
require('@formatjs/intl-relativetimeformat/dist/locale-data/de');
require('@formatjs/intl-relativetimeformat/dist/locale-data/bn');
require('@formatjs/intl-relativetimeformat/dist/locale-data/cs');

const fetchLocale = async (locale: string) => {
    try {
        if (locale === 'en') return enMessages;
        const response = await fetch(`/l10n/${locale}.json`);
        if (!response.ok) throw Error(response.statusText);
        const messages: { [key: string]: string } = await response.json();
        return messages;
    } catch (error) {

    }
    return enMessages;
};

interface ReactIntlProps {
    children: React.ReactChild;
}

const ReactIntlProvider = ({ children }: ReactIntlProps) => {
    const [messages, setMessages] = useState<{ [key: string]: string }>();
    const { language } = useContext(LangContext); // selected language
    const [fetchedlang, setFetchedLang] = useState(language);

    useEffect(() => {
        // fetch lang json on language change
        async function asyncFunc() {
            const fetchedMsgs = await fetchLocale(language);
            setMessages(fetchedMsgs);
            setFetchedLang(language);
        }
        asyncFunc();
    }, [language]);
    return (
        <IntlProvider locale={fetchedlang} messages={messages}>
            {children}
        </IntlProvider>
    );
};

export default ReactIntlProvider;
