import Head from 'next/head';
import React from 'react';

const Favicon: React.FC = () => {
    const faviconPath = '/favicon/favicon.svg';
    const lightModeFaviconPath = '/favicon/light-favicon.svg';
    const darkModeFaviconPath = '/favicon/dark-favicon.svg';

    const faviconPNGPath = '/favicon/favicon.png';

    return (
        <Head>
            {/*<title>Obalero</title>*/}
            {/* SVG Favicon */}
            <link rel="icon" type="image/svg+xml" href={faviconPath} />

            {/* Light Mode SVG Favicon */}
            <link rel="icon" type="image/svg+xml" href={lightModeFaviconPath} media="(prefers-color-scheme: light)" />

            {/* Dark Mode SVG Favicon */}
            <link rel="icon" type="image/svg+xml" href={darkModeFaviconPath} media="(prefers-color-scheme: dark)" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
            {/*/!* ICO Favicon *!/*/}
            {/*<link rel="icon" type="image/x-icon" href={faviconICOPath} />*/}

            {/* PNG Favicon */}
            <link rel="icon" type="image/png" href={faviconPNGPath} />

            <link rel="apple-touch-icon" href={faviconPath} />
            <meta name="theme-color" content="#ffffff" />
        </Head>
    );
};

export default Favicon;